import {
  Box,
  Stack,
  Button,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import Image from '#/components/shared/ui/Image';
import CustomTextField from '../../components/shared/custom-input';
import useCountdown from '../../lib/hooks/useCountdown';
import Iconify from '#/components/shared/ui/Iconify';
import Page from '#/components/shared/ui/Page';

export default function ComingSoon() {
  const { days, hours, minutes, seconds } = useCountdown(
    new Date('07/07/2024 21:30')
  );

  return (
    <Stack
      sx={{
        py: 12,
        maxWidth: 400,
        textAlign: 'center',
        m: 'auto',
        minHeight: '100vh',
        justifyContent: 'center',
      }}
    >
      <Page title="Coming Soon">
        <Typography variant="h3" paragraph>
          Coming Soon!
        </Typography>

        <Typography sx={{ color: 'text.secondary' }}>
          We are currently working hard on this page!
        </Typography>

        <Image
          sx={{ my: { xs: 5, sm: 10 } }}
          src="/assets/icons/coming_soon.svg"
        />

        <Stack
          direction="row"
          justifyContent="center"
          divider={<Box sx={{ mx: { xs: 1, sm: 2.5 } }}>:</Box>}
          sx={{ typography: 'h2' }}
        >
          <TimeBlock label="Days" value={days} />

          <TimeBlock label="Hours" value={hours} />

          <TimeBlock label="Minutes" value={minutes} />

          <TimeBlock label="Seconds" value={seconds} />
        </Stack>

        <CustomTextField
          fullWidth
          placeholder="Enter your email"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button variant="contained" size="large">
                  Notify Me
                </Button>
              </InputAdornment>
            ),
            sx: { pr: 0.5 },
          }}
          sx={{ my: 5 }}
        />
        <Stack direction="row" justifyContent="center" spacing={2}>
          <IconButton>
            <Iconify icon="eva:facebook-fill" color="#1C9CEA" />
          </IconButton>

          <IconButton color="inherit">
            <Iconify icon="skill-icons:instagram" color="#DF3E30" />
          </IconButton>

          <IconButton>
            <Iconify icon="eva:linkedin-fill" color="#1C9CEA" />
          </IconButton>

          <IconButton>
            <Iconify icon="eva:twitter-fill" color="#1C9CEA" />
          </IconButton>
        </Stack>
      </Page>
    </Stack>
  );
}

type TimeBlockProps = {
  label: string;
  value: string;
};

function TimeBlock({ label, value }: TimeBlockProps) {
  return (
    <div>
      <Box> {value} </Box>
      <Box sx={{ color: 'text.secondary', typography: 'body1' }}>{label}</Box>
    </div>
  );
}
